<template>
  <div>
    <q-select
      v-model="selectedLocale"
      class="language-button font--b5-2 text-black-monochrome"
      option-value="code"
      outlined
      option-label="name"
      :options="locales"
      @update:model-value="switchLanguage"
    />
  </div>
</template>

<script setup lang="ts">
const { locale, locales, setLocale } = useI18n();
const availableLocalesData = [
  { code: "en", name: "English", language: "en-US" },
  { code: "de", name: "Deutsch", language: "de-DE" },
];

const $q = useQuasar();

const selectedLocale = ref(
  availableLocalesData.find((loc) => loc.code === locale.value) ||
    availableLocalesData[0]
);

const switchLanguage = async (newLocale) => {
  if (newLocale && newLocale.code !== locale.value) {
    $q.loading.show();
    try {
      selectedLocale.value = newLocale;
      await setLocale(newLocale.code);
    } finally {
      $q.loading.hide();
    }
  }
};
</script>

<style lang="scss">
.language-button {
  .q-field__control {
    padding: 0;
    .q-field__native {
      * {
        font-size: 14px !important;
      }
    }
    &::before {
      border: none !important;
    }
    &::after {
      border: none !important;
    }
  }
  :deep(.q-field__inner) {
    * {
      border: none !important;
    }
  }
}
</style>
